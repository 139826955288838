.form-external-provider {
    text-align: center;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .external-options-label {
        top: 30px;
        padding-left: 0;
    }

    .external-provider-but {
        cursor: pointer;
        width: 100%;
        height: 39px;
        -ms-border-radius: 2px;
        border-radius: 2px;
        text-align: left;
        background-color: #fff;
        box-shadow: 0 0 2px 0 #000000, 0 2px 2px 0 #000000; /*fallback color*/
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.30), 0 2px 2px 0 rgba(0, 0, 0, 0.20);
    }

    .external-provider-but img {
        left: 5%;
    }

    .external-provider-but span {
        left: 16%;
    }

    .circleContainer {
        background: transparent;
        left: 40%;
    }
}

@media (max-width: 767px) {
    .external-options-label {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .external-provider-but {
        cursor: pointer;
        width: 100%;
        height: 39px;
        -ms-border-radius: 2px;
        border-radius: 2px;
        text-align: left;
        background-color: #fff;
        box-shadow: 0 0 2px 0 #000000, 0 2px 2px 0 #000000; /*fallback color*/
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.30), 0 2px 2px 0 rgba(0, 0, 0, 0.20);
        display: inline-block;
    }

    .external-provider-but img {
        left: 7%;
    }

    .external-provider-but span {
        left: 22%;
    }

    .circleContainer {
        background: none;
        left: 35%;
    }
}

.external-provider-but:hover {
    background-color: #ccebff;
}

.external-provider-but:active {
    background-color: #eeeeee;
    box-shadow: 0 2px 2px 0 #000000, 0 0 2px 0 #000000; /*fallback color*/
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.external-auth-button-disabled {
    pointer-events: none;
    background-color: #000000; /*fallback color*/
    background-color: rgba(0, 0, 0, 0.08);
}

.external-provider-but > div {
    height: 100%;
    text-align: left;
}

.external-provider-but img {
    position: absolute;
    top: 25%;
    max-height: 18px;
    max-width: 18px;
    border: 0;
}

.external-provider-but.bankId img {
    top: 30%;
    max-width: 60px;
    left: 1%;
}

.external-provider-but span {
    top: 25%;
    position: relative;
}


.circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    font-size: 14px;
    color: #000;
    text-align: center;
    background: #fff;
    border: 1px solid #dcdcdc;
    margin: -25px auto 0 auto;
}

.circle p {
    margin-top: 15px !important;
    text-transform: uppercase;
}

.circleContainer {
    width: 70px;
    position: absolute;
    top: 0;
}
