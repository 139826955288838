/**** Fonts ****/
/* Webfont: PFBeauSansPro-Thin */
/* Webfont: PFBeauSansPro-XThin */
@font-face {
    font-family: 'PFBeauSansProXThin';
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot');
    /* IE9 Compat Modes */
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-XThin.woff') format('woff'), /* Modern Browsers */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-XThin.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-XThin.svg#PFBeauSansPro-XThin') format('svg');
    /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: PFBeauSansPro-Light */
@font-face {
    font-family: 'PFBeauSansProLight';
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Light.eot');
    /* IE9 Compat Modes */
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Light.woff') format('woff'), /* Modern Browsers */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Light.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Light.svg#PFBeauSansPro-Light') format('svg');
    /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: PFBeauSansPro-Regular */
@font-face {
    font-family: 'PFBeauSansProRegular';
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot');
    /* IE9 Compat Modes */
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff') format('woff'), /* Modern Browsers */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-Regular.svg#PFBeauSansPro-Regular') format('svg');
    /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: PFBeauSansPro-SemiBold */
@font-face {
    font-family: 'PFBeauSansProSemiBold';
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot');
    /* IE9 Compat Modes */
    src: url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff') format('woff'), /* Modern Browsers */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://font.visma.com/Fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.svg#PFBeauSansPro-SemiBold') format('svg');
    /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: OpenSans-Light */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/OpenSans-Light-webfont.eot"), url("../fonts/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light-webfont.woff") format("woff"), url("../fonts/OpenSans-Light-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Light-webfont.svg#OpenSansLight") format("svg");
}
/* Webfont: OpenSans-Webfont */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/OpenSans-Regular-webfont.eot"), url("../fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Regular-webfont.svg#OpenSans") format("svg");
}
/* Webfont: OpenSans-Semibold */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/OpenSans-Semibold-webfont.eot"), url("../fonts/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Semibold-webfont.woff") format("woff"), url("../fonts/OpenSans-Semibold-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Semibold-webfont.svg#OpenSansSemibold") format("svg");
}
/* Webfont: OpenSans-Bold */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/OpenSans-Bold-webfont.eot"), url("../fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Bold-webfont.svg#OpenSansBold") format("svg");
}
