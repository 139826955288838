#language-selector img {
    height: 22px;
    width: 20px;
    display: inline-block;
    margin: 0 0 0 20px;
    padding-top: 2px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

#language-selector a {
    margin-left: 0;
}

#language-selector li {
    display: block;
}

#language-selector #language-dropdown-menu {
    min-width: 240px;
    padding-top: 15px;
    margin-bottom: 30px;
}

#language-selector #language-dropdown-menu.poz-compensate {
    right: -60px;
}

#language-selector #language-dropdown-menu:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: #ffffff transparent transparent;
    border-color: rgba(255,255,255,.95) transparent transparent;
    bottom: -8px;
    left: 45%;
}

#language-selector #language-dropdown-menu > li {
    margin-bottom: 5px;
}

#language-selector #language-dropdown-menu img {
    margin: 0;
}

#language-selector #language-dropdown-menu .language-img-container {
    display: inline-block;
    margin-right: 10px;
    margin-left: 20px;
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
}

#language-selector #language-dropdown-menu .language-img-container img {
    border: 0;
}

#language-selector img.da-DK {
    background-image: url("/img/countries/dk.png");
}

#language-selector img.fi-FI {
    background-image: url("/img/countries/fi.png");
}

#language-selector img.en-GB {
    background-image: url("/img/countries/gb.png");
}

#language-selector img.en-US {
    background-image: url("/img/countries/us.png");
}

#language-selector img.nl-NL {
    background-image: url("/img/countries/nl.png");
}

#language-selector img.nb-NO {
    background-image: url("/img/countries/no.png");
}

#language-selector img.sv-SE {
    background-image: url("/img/countries/se.png");
}

#language-selector img.lv-LV {
    background-image: url("/img/countries/lv.png");
}
