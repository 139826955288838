#page-impersonation-granted {
  & .message-element {
    font-size: 28px;
  }

  & .vismaicon {
    display: none;
  }

  & .list-group-item {
    display: block;
    padding: 15px 0;
    position: relative;

    &:last-child {
      margin-bottom: -15px;
    }

    &:after {
      content: "\f104";
      display: inline-block;
      font-family: e-conomic;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  & .list-group-item + .list-group-item {
    border-top: 1px solid #dddddd;
  }

  & a {
    color: black;

    & h4 {
      margin-bottom: 0;
    }
  }
}
