#page-register-connect,
.form-electronicid-register {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & form {
    width: 100%;
  }

  & .form-group {
    width: 100%;
  }

  & .form-group-language {
    width: 50%;
    padding-right: 10px;
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  & .form-group-country {
    width: 50%;
    padding-left: 10px;
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  & .form-group-country + * {
    clear: both;
  }

  & .form-connect-register label.control-label {
    display: flex;
  }

  & .form-connect-register input[type="text"]::placeholder {
    visibility: hidden;
  }

  & .form-connect-register input[type="email"]::placeholder {
    visibility: hidden;
  }
}
