#page-password-success {
  & .message-box > div {
    display: flex;
    flex-direction: row;

    & #LoginLink {
      display: block;
      margin-top: 20px;
    }
  }
}
