#page-electronicid-login {
  & form {
    /* display: flex; */
    /* flex-direction: column; */

    & .form-group-forgot-password {
      width: 50%;
      float: right;
      margin-top: 30px;
    }

    & .form-group-back-to-login {
      width: 50%;
      float: right;
      margin-top: 30px;
    }
  }

  & .pull-right {
    float: right;
  }
}
