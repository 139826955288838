#page-consent-index {
  & ul {
    list-style-type: none;
    padding: 0;

    & li {
      color: #18073a;
      font-size: 24px;
      letter-spacing: 0.2px;
      line-height: 28px;

      border-bottom: 1px solid #dddddd;
    }
  }
}

@media only screen and (max-width: 460px) {
  #page-consent-index ul li h4 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
