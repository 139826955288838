#page-login-index {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    width: 100%;
  }

  & .form-group-login {
    order: 1;
  }

  & .change-to-connect-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  & .change-to-connect-container h4 {
    margin-bottom: 20px;
  }
}
