#page-electronicid-link {
  & .form-content .form-footer {
    &::before {
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      left: 0;
      content: "";
      background: #ddd;
    }
  }

  & .form-group-button-block {
    position: relative;

    & .btn {
      background-color: transparent;
      border-radius: 0;
      text-align: left;
      margin: 0;
      padding: 0 20px 0 0;

      color: var(--text-color2);
      font-family: "Open Sans", sans-serif;
      font-size: 16px;

      &:hover {
        box-shadow: none;
      }

      &:after {
        content: "\f104";
        display: inline-block;
        font-family: e-conomic;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  & .padding-top {
    padding-top: 20px;
  }

  & .form-footer {
    margin-top: 20px;
  }
}
