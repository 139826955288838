@import url(./variables.css);
@import url(./fonts.css);
@import url(./icons.css);
@import url(./theme-base.css);
@import url(./theme-economic.css);
@import url(./theme-icons.css);

/* pages are always included in alphabetical order */
@import url(./page-consent-index.css);
@import url(./page-electronicid-link.css);
@import url(./page-electronicid-login.css);
@import url(./page-impersonation-granted.css);
@import url(./page-info-index.css);
@import url(./page-login-index.css);
@import url(./page-password-expire-warning.css);
@import url(./page-password-success.css);
@import url(./page-register-connect.css);
@import url(./page-totp-alternatives.css);
@import url(./page-totp-enter-emergency-code.css);
@import url(./page-totp-index.css);
@import url(./page-twostepsetup-authenticator-enable.css);

@import url(./dependencies/external-auth.css);
@import url(./dependencies/language-selector.css);

/*ADDED BY CONNECT TEAM*/
.wizard-step-bottom,
#totp-lock-img-container,
#totp-confirmation-icon {
  display: none;
}
