@import url(./variables.css);

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: var(--text-color2);
  font-size: 16px;
}

a {
  cursor: pointer;
}

* {
  transition: all 0.2s ease;
  transition: color 0s;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin: 0 0 var(--small-space) 0;
}

h3,
h4 {
  font-weight: 300;
}

hr.row-split {
  color: #dddddd;
  opacity: 0.5;
}

.main-container {
  min-height: 100%;
  display: flex;
  flex-direction: row;

  & .cookie-overlay {
    width: 100%;
    position: fixed;
    bottom: 0;
    min-height: 65px;
    background-color: white;
    color: var(--text-color2);
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    font-size: 12px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & a {
      margin-right: 10px;
    }

    & .cookie-overlay-wrapper {
      padding: 10px 30px;
      text-align: left;
    }

    & .cookie-hide {
      font-size: 14px;
      min-height: initial;
      height: 30px;
      width: 57px;
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);

      &:hover {
        box-shadow: 0 1px 6px 0 rgba(153, 153, 153, 0.9);
      }
    }
  }

  & .side-image {
    width: 50%;
    height: 100%;
    background: url("campaign-2FA-login-2025-1.jpg") no-repeat center center;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
  }

  & .logo-container {
    max-width: var(--max-width);
    width: 100%;
    margin: 50px auto 0 auto;

    & .logo {
      height: 24px;
      /* Hide default logo on this page */
      display: none;
    }
  }

  & .inner-container {
    width: 50%;
    margin-left: 50%;
    min-height: 100%;
    position: relative;
    padding: 0 20px 160px 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & .body-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px 0;
    }

    & .footer-container {
      width: 100%;

      position: absolute;
      right: 0;
      bottom: 50px;
      left: 0;
      margin-top: auto;

      color: #aaaaaa;
      font-size: 14px;
      letter-spacing: 0.2px;

      & a {
        color: #aaaaaa;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      & .footer-inner {
        max-width: var(--max-width);
        margin: 0 auto;
      }

      & .status-page-container {
        display: inline-block;
        line-height: 33px;
      }

      & .footer-list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
      }

      & .footer-list > li {
        float: left;
        line-height: 33px;
      }
    }
  }
}

.container-box,
.form-box,
.content-box {
  margin: 0 auto;
  max-width: var(--max-width);
  width: 100%;
}

.form-box {
  & .form-logo {
    display: none;

    &.theme-header {
      display: initial;
    }
  }
}

.btn {
  min-height: 46px;
  font-size: 19px;
  line-height: 22px;
  font-weight: 300;
  border-radius: 23px;
  border: none;
  outline: 0;
  width: 100%;
  background-color: #f3f4f6;
  color: #1e0d3f;
  user-select: none;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0 1px 6px 0 rgba(153, 153, 153, 0.9);
  }

  /* Copy to clipboard is a special button that looks completely different */
  &.copyToClipboard {
    width: 46px;
    max-width: 46px;
    min-width: 46px;
    border-left: 1px solid #dddddd;

    & .vismaicon-copy {
      background-image: url("../../icon-copy.svg");
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      border-radius: 0;
      width: 20px;
      min-width: 20px;
      height: 21px;
      min-height: 21px;
      display: inline-block;
      margin: 0;
      padding: 0;
    }
  }
}

#language-selector {
  position: relative;

  & img {
    margin-bottom: -6px !important;
  }

  & #language-dropdown-menu {
    visibility: hidden;
    opacity: 0;
    display: none;

    position: absolute;
    top: initial;
    bottom: 100%;
    left: 0;
    right: initial !important;
    padding-left: 20px;
    margin-bottom: 0 !important;

    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #e7e7e7;

    &::after {
      /* Remove bottom arrow for new theme */
      border: none !important;
    }
  }

  & .open #language-dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.clear,
.clearfix {
  clear: both;
}

.alert {
  & > * {
    display: flex;
    flex-direction: row;
  }

  & h4 {
    font-size: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    margin: 0 0 var(--small-space) 0;
  }

  & p {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }
}

.alert,
.form-group {
  & label.control-label {
    display: none;
  }

  & a {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.21px;
    line-height: 19px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.theme-message {
  & > div {
    display: flex;
    flex-direction: row;
  }
}

/* Float message box icon on the left */

.vismaicon {
  align-self: flex-start;
  font-family: e-conomic;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: none;
  text-decoration: inherit;
  width: 1em;
  margin: 0;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 19px;
  line-height: 33px;

  height: 35px;
  width: 35px;
  min-width: 35px;
  border-radius: 90px;
  margin-right: 15px;

  &.vismaicon-info {
    display: inline-block;
    background-color: rgba(76, 132, 255, 0.1);

    &::before {
      content: "\f11b";
      color: rgb(25, 141, 250);
    }
  }

  &.vismaicon-success {
    display: inline-block;
    background-color: #c8f7ed;

    &::before {
      content: "\f101";
      color: rgb(0, 213, 168);
    }
  }

  &.vismaicon-error {
    display: inline-block;
    background-color: rgba(253, 99, 97, 0.1);

    &::before {
      content: "\f11b";
      color: rgb(255, 94, 98);
    }
  }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;

  border-bottom: 1px solid #ddd;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.2px;

  &:focus {
    outline-style: none;
    box-shadow: none;
  }

  &:hover {
    /* box-shadow: 0 1px 1px 0 rgba(153, 153, 153, 0.9); */
    border-bottom: 1px solid var(--text-color2);
  }

  &::placeholder {
    font-weight: 100;
    color: #aaaaaa;
    font-size: 17px;
    letter-spacing: 0.2px;
    text-transform: lowercase;
  }
}

input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  border: 1px solid #dddddd;
  padding: 8px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  margin-bottom: -2px;
  margin-right: 5px;
  margin: 0 10px 0 0;
  height: 8px;
  width: 8px;

  &:active,
  &:checked:active {
  }

  &:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    color: #99a1a7;
  }
  &:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #99a1a7;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }

  .external-providers {
    & .form-external-provider {
      & .external-provider-but {
        & img {
          height: 18px;
        }
      }
    }
  }
}

div.checkbox > * {
  display: flex;
  flex-direction: row;
}

.form-group-rememberusername div.checkbox {
  & > * {
    display: block;
  }

  & p.text-disabled {
    font-style: italic;
    margin-top: 0;
  }
}

.form-group-login-links {
  text-align: center;
  
  & > div {
    margin: 40px 0;
  }
}


.dropdown-menu-holder {
  position: relative;

  &:after {
    content: "\f102";
    display: inline-block;
    font-family: e-conomic;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  & li {
    padding: 5px 20px;

    & a {
      color: var(--text-color2);
    }

    &:hover {
      background-color: #f3f4f6;
    }
  }

  & .dropdown-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    margin-top: 0;
    display: none;
    list-style-type: none;
    padding: 10px 0;
    z-index: 1;
    top: 36px;
    width: 100%;

    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #dddddd;
    border-left: 1px solid #e7e7e7;
  }

  &.open .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  &.open:after {
    transition: all 0.2s ease;
    transform: translate(0, -50%) rotate(180deg);
  }
}

.external-providers {
  clear: both;
  position: relative;

  & .circleContainer {
    display: none;
    left: 50%;
    transform: translate(-50%, 0);
  }

  & .form-external-provider {
    border: 1px solid #ebebeb;
    border-radius: 23px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(153, 153, 153, 0.2);
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 22px;
    padding-right: 22px;

    &:hover {
      box-shadow: 0 1px 6px 0 rgba(153, 153, 153, 0.5);
    }

    & .external-provider-but {
      margin-right: 12px;
      line-height: 22px;

      box-shadow: none;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        background-color: transparent;
      }

      & span {
        align-self: center;
        left: initial;
        top: initial;
      }
    }
  }
}

.btn.btn-default.dropdown-toggle {
  min-height: 36px;
  text-align: left;
  font-size: 14px;
  background: transparent;
  border-radius: 0;
  border: 1px solid #dddddd;
  padding: 0 12px;

  &:hover {
    box-shadow: 0 1px 6px 0 rgba(153, 153, 153, 0.2);
  }
}

.message-box > div {
  display: flex;
  flex-direction: row;
}

.form-group + .form-group {
  margin-top: 20px;
}

.form-group.form-group-no-margin,
.no-margin {
  margin: 0;
}

.form-group-forgot-password {
  & .pull-right {
    float: right;
  }
}

.g-recaptcha {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group-link {
  margin-top: 30px !important;
  text-align: center !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.theme-header {
  font-size: 28px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin: 0 0 var(--small-space) 0;
  color: #18073a;
  line-height: 33px;
  margin-top: 0;
  margin-bottom: 20px;

  & * {
    margin: 0;
  }
}

.form-footer {
  margin-top: 40px;
}

.center-block {
  display: block;
  float: none;
  margin-right: auto;
  margin-left: auto;
}

.form-group-input-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:nth-child(2) {
    flex-grow: 1;
  }

  & .icon-phone {
    height: 40px;
    margin-right: 20px;
  }

  & input {
    margin-top: 0;
    flex-grow: 1;
  }
}

.hide,
.hide-in-new-theme {
  display: none !important;
}

.numbered-circle {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 14px;

  text-align: center;
  line-height: 36px;

  border: 1px solid #dddddd;
  border-radius: 50%;
}

.badge-apple {
  height: 35px;
  float: left;
  margin-left: calc(36px + 14px);
  margin-right: 20px;
  margin-bottom: 10px;
}

.badge-google {
  height: 35px;
  float: left;

  &::after {
    clear: both;
  }
}

.center-text {
  text-align: center;
}

.form-group-center {
  text-align: center;
}

.mr-15 {
  margin-right: 15px;
}

.form-group-copy {
  border: 1px solid #dddddd;
  border-radius: 5px;
  position: relative;

  & > * {
    display: flex;
    flex-direction: row;
  }

  & span {
    align-self: center;
    text-align: center;
    flex-grow: 1;
  }

  & .btn {
    background-color: transparent;
    border-radius: 0;
  }

  & .tooltip {
    background-color: white;
    border: 1px solid #dddddd;
    padding: 20px;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.wizard-step {
  display: inline-block;
  margin-bottom: 40px;
}

.wizard-step-count {
  color: #a5a5a5;
}

.form-footer.theme-buttons,
.form-group.theme-buttons,
.theme-buttons {
  clear: both;
  margin-top: var(--large-space);

  & .btn {
    width: 46%;
  }

  & .btn-primary {
    float: right;
  }
}

.form-group-cta,
.form-group.form-group-cta {
  margin-top: 40px;
}

.form-setup-header {
  margin-bottom: 20px;
}

.split-link a {
  display: block;
  margin-top: var(--small-space);
}

/*
  Force overrides of default styles
*/

.form-group .link-container {
  height: initial !important;
}

.form-group-pickers {
  display: flex;
  flex-direction: row;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
  }

  & > div:nth-child(1) {
    margin-right: 10px;
  }
  & > div:nth-child(2) {
    margin-left: 10px;
  }

  & .form-group + .form-group {
    margin-top: 0;
  }
}

/* 
  Media queries
*/

@media only screen and (max-width: 1024px) {
  .main-container {
    flex-direction: column;

    & .cookie-overlay {
      & a {
        margin-bottom: 10px;
      }
      & .btn {
        display: block;
      }

      & .cookie-overlay-wrapper {
        display: block;
        padding-right: 70px;

        & button {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    & .side-image {
      display: none;
    }

    & .inner-container {
      width: 100%;
      max-width: var(--max-width);
      margin-left: auto;
      margin-right: auto;
      padding: 20px;

      & .footer-container {
        bottom: 0;
        position: relative;

        & .footer-inner {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .form-box {
    margin: 0 0 30px 0;
  }

  .logo-container {
    margin-top: 20px;
    width: initial;
    max-width: initial;

    & .logo {
      height: 20px;
      margin: 0 0 20px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-container {
    & .inner-container {
      & .footer-container {
        & .footer-list {
          float: none;

          & li {
            float: none;

            & img {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  .external-providers {
    & .form-external-provider {
      padding-left: 10px;
      padding-right: 10px;

      & .external-provider-but {
        & img {
          margin-right: 0;
        }
      }
    }
  }

  .form-group-pickers {
    display: block;

    & > div:nth-child(1) {
      margin-right: 0;
    }
    & > div:nth-child(2) {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 460px) {
  .btn {
    font-size: 16px;
  }

  .main-container {
    & .logo-container {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    & .inner-container {
      & .body-content {
        padding: 0;
        justify-content: start;
      }

      & .footer-container {
        font-size: 15px;
      }
    }
  }

  .external-providers {
    font-size: 14px;
  }

  /* Prevent overflow on small screens */
  .g-recaptcha {
    transform: scale(0.93);
    transform-origin: 0.5 0.5;
  }

  /* On mobile remove left padding on message pages */
  .alert {
    & p {
      margin-left: -50px;
    }
  }

  #page-electronicid-register-pending .form-content p,
  #page-electronicid-register-pending .waiting-holder {
    margin-left: -50px;
  }

  #page-forgot-success #ErrorLocked,
  #page-password-success #LoginLink {
    margin-left: -50px;
    display: inline-block;
  }

  /* Really nasty way of left aligning two p tags, but not the third */
  #page-u2f-incompatible-browser .padding-left-70 {
    margin-left: -50px;
  }
  #page-u2f-incompatible-browser .padding-left-70.theme-header {
    margin-left: 0;
  }
  #page-u2f-incompatible-browser .pl-50 {
    margin-left: -50px;
  }
}

/* Fix for password popover */
.popover {
  position: static !important;
  top: 0 !important;

  & .popover-content {
    & li {
      font-size: 12px;
    }
    & .vismaicon {
      font-size: 12px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
}

.u2f-spinner {
  position: relative;
  margin: 20px 0;
}

.form-group-icon-title {
  & .flex-row {
    align-items: center;
  }
  & img {
    margin-right: 15px;
  }
}

.flex-row {
  align-items: center;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.pl-50 {
  padding-left: 50px;
}

.hidden {
  display: none !important;
}

@media (max-width:767px) {
  .hidden-xs {
    display:none !important;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display:none !important;
  }

}@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display:none !important;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display:none !important;
  }
}

.visma-logo,
.site-name,
.password-policies,
.error-page-image,
.icon-nc3 {
  display: none;
}

/* Truncate language name with ellipsis so that it fits inside dropdown */
#selected-language-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  margin-top: 4px;
}

#register-back-to-signin {
  text-align: left;
}

#page-unlock-index a {
  display: block;
  margin-top: 20px;
}

#page-totp-renew-emergency-code {
  & .pl-50 {
    padding-left: 0;
  }
}

#page-changemail-index {
  & #LoginButton {
    margin-top: 20px;
  }

  & #ForgotPassword {
    float: right;
  }
}

#page-password-change {
  & #ButtonChangePassword {
    margin-top: 20px;
  }
}

#page-password-reset {
  & #ButtonResetPassword {
    margin-top: 20px;
  }
}