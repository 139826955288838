#page-twostepsetup-authenticator-enable {
    .form-group+.form-group {
        text-align: center;
    }

    .form-group.no-padding.text-center {
        text-align: center;
    }

    .col-xs-6.no-left-padding {
        display: inline-block;
        text-align: center;
    }

    .badge-apple {
        height: 50px;
        max-width: 160px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .badge-google {
        height: 50px;
        max-width: 160px;
        margin-left: 10px;
      
        &::after {
          clear: both;
        }
      }
}