#page-totp-alternatives {
  & .panel {
    & .totp-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:after {
        content: "\f104";
        display: inline-block;
        font-family: e-conomic;
        position: absolute;
        right: 0;
      }
    }

    & > * {
      margin-bottom: 10px;
      position: relative;

      color: var(--text-color2);

      &:hover {
        text-decoration: none;
      }

      & + * {
        border-top: 1px solid #dddddd;
        padding-top: 10px;
      }
    }
  }
}

/* .totp-row {
  margin-bottom: 10px;
  position: relative;

  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & img {
    max-width: 25px;
    max-height: 40px;
    margin-right: 15px;
  }

  & p {
    line-height: 36px;
  }
} */
